import React, { Component } from 'react'

export default class ErrorPage extends Component {
  render() {
    return (
      <header className="center-align row col s12">
        <h1>404!</h1>
        <h2>Page not found</h2>
        <p>Make sure you have the customer's name and a quote selected.</p>
      </header>
    )
  }
}