import config from './config'

const ApiService = {
  // get all the images associated to the specific projects
  getImages(id) {
    // call token function here instead of storing/getting token in config
    return this.getToken()
    .then(accessToken =>{
      // console.log("token: ",accessToken)
      return fetch(`${config.API_ENDPOINT}/${id}/photos/?per_page=150`, {
        "method": 'GET',
        "headers": {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + accessToken.authToken
      }
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(e => Promise.reject(e))
          : res.json()
      )
      .then(data => {
            console.log("Response JSON: ", data);
            return data;
      })
    })
  },
  pushToCreator(res, id) {
      let Inspection_Images = []
      res.map((img,idx) => {
        const imgObj = { "Image": img.src,"Quotes":id, "Image_Title":idx+1 }
        return Inspection_Images = [...Inspection_Images, imgObj]
      })
      const updateBody = { "data":{"data": Inspection_Images}}
      console.log(updateBody)
      return fetch(`${config.expressUrl}/api/`,{
        "method":"POST",
        "headers":{
          "content-type":"application/json"
        },
        "body": JSON.stringify(updateBody)
      })
      .then(res =>
        (!res.ok)
        ? res.json().then(e => Promise.reject(e))
        : res.json()
      )
  },
  updateQuote(id){
    const data = {"data":{"data":{"Refresh_Inspection_Images":Date.now()}}}
    return fetch(`${config.expressUrl}/api/updateQuote/${id}`,{
      "method":"POST",
      "headers":{
        "content-type":"application/json"
      },
      "body": JSON.stringify(data)
    })
    .then(res =>
      (!res.ok)
      ? res.json().then(e => Promise.reject(e))
      : res.json()
    )
  },
  getToken(){
    return fetch(`${config.expressUrl}/api/getToken`,{
      "method":"GET",
      "headers":{
        "content-type":"application/json"
      }
    })
    .then(res =>
      // console.log(res)
      (!res.ok)
      ? res.json().then(e => Promise.reject(e))
      : res.json()
    )
    // .then(res =>{
    //   return res
    // })
  }
}

export default ApiService
