import React, { Component } from 'react'
import ApiService from './apiService'
import ImagePicker from 'react-image-picker'
import { withRouter } from 'react-router-dom'
import 'react-image-picker/dist/index.css'

class SelectionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      res: [],
      images: [],
      error: null,
      tokenRefreshAttempted:false
    }
    this.onPick = this.onPick.bind(this)
  }

  static defaultProps = {
    onSuccess: () => { },
  }

  onPick(res) {
    this.setState({ res })
  }

  handleSelectionSuccess = () => {
    // console.log(this.props.quoteID)
    ApiService.pushToCreator(this.state.res, this.props.quoteID)
      .then(res => {
        const { history } = this.props;
        console.log("return res: ",res)
        if(res.code === 3000){
          // ApiService.updateQuote(this.props.quoteID)
          history.push('/success')
        }
      })
  }

  // cancel = () => {
  //   window.open("about:blank", "_self");
  //   window.close();
  // }

  getProjects(){
    const id = this.props.projectId
    //  const projectNumber = this.props.projectId
    // ApiService.getProjects()
    //   .then(res => {
    //     res.map(proj => {
          // this is where I plug in the paramater should be project number and quote id
          // split name to find look up number
          // const projNum = proj.name.split(' ')[0]
          // console.log(projNum)
          // for test comment this in
          // if(proj.id === id.toString()){
          // and comment the below one out
          // if (projNum == projectNumber && proj.status !== 'deleted') {
            // console.log(proj)
            return ApiService.getImages(id)
              .then(resp => {
                console.log("Resp: ", resp)
                if (resp.length > 0) {
                  this.setState({
                    images: resp,
                    error: null,
                    tokenRefreshAttempted:false
                  })
                } else {
                  // project found, but it doesn't have any photos yet
                  return this.setState({
                    error: "No Photos Found"
                  })
                }
              })
          // }
          // else {
          //   // nothing matches the code passed from creator
          //   return this.setState({
          //     error: "No project found"
          //   })
          // }
        // })
      // })
      // .catch(e => {
      //   console.log(e)
      //   if(e.errors){
      //     if ((e.errors[0] === 'Unauthorized') && this.state.tokenRefreshAttempted === false) {
      //       ApiService.refreshToken()
      //       .then(()=>{
      //         this.setState({tokenRefreshAttempted: true})
      //         this.getProjects()
      //       })
      //     } else {
      //       this.setState({ error: e.error })
      //     }
      //   }
      // })
  }

  componentDidMount() {
    this.getProjects()
  }

  render() {
    let error;
    // console.log(this.state.res)
    if (this.state.error != null) {
      error = this.state.error
    } else {
      error = null;
    }
    return (
      <div>
        {error ? error : ""}
        <ImagePicker
          images={this.state.images.map(image => ({ src: image.uris[3].url, value: image.id }))}
          onPick={this.onPick} multiple={'multiple'}
        />
        {/* <button className="btn" onClick={this.cancel}>Cancel</button> */}
        <button className="btn" type="button" onClick={this.handleSelectionSuccess}>Insert</button>
      </div>
    )
  }
}

export default withRouter(SelectionForm)
