import React, { Component } from 'react'

export default class SuccessPage extends Component {
  render() {
    return (
      <header className="center-align row col s12">
        <h1 className="green-text">Success!</h1>
        <h5>Images Inserted to Quote</h5>
        <h5>*The form will need to be reloaded to reflect the changes*</h5>
        <p>You can close this window</p>
      </header>
    )
  }
}