import React, { Component } from 'react'
import SelectionForm from './selectionForm'


export default class SelectionPage extends Component {
  render() {
    return (
      <section className="App">
          <header>
            <h4>Select Images to Insert</h4>
            {/* <i>*The first image selected will be the hero image on the quote*</i> */}
          </header>
          <SelectionForm onSelectionSuccess={this.handleSelectionSuccess} projectId={this.props.match.params.pid} quoteID={this.props.match.params.qid} />
      </section>
    )
  }
}
