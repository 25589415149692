import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import ErrorPage from './errorPage';
import SuccessPage from './successPage';
import SelectionPage from './selectionPage'
import './App.css';

class App extends Component {
  render() {
    return (
      <main className="container">
        <Switch>
          <Route
            exact
            path={'/'}
            component={ErrorPage}
          />
          <Route
            exact
            path={'/:pid/:qid'}
            render={(props) => <SelectionPage {...props} />}
          />
          <Route
            exact
            path={'/success'}
            component={SuccessPage}
          />
          <Route
            component={ErrorPage}
          />
        </Switch>
      </main>
    );
  }
}

export default App;
